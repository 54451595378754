import { Component } from '@angular/core';

@Component({
  selector: 'app-newest-aids',
  templateUrl: './newest-aids.component.html',
  styleUrls: ['./newest-aids.component.scss']
})
export class NewestAidsComponent {

}
