<div class="full-width">
    <section class="container">
        <h2>Nejnovější rozvojové pomůcky</h2>
        <div class="subtitle">nejen pro komunikaci</div>
        <div class="slider">
            <app-aid-card></app-aid-card>
            <app-aid-card></app-aid-card>
            <app-aid-card></app-aid-card>
        </div>
    </section>
</div>