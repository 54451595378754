import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-logo]',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.01259 37.97">
      <style>
        path {
          fill: {{color}};
          stroke: none;
        }
      </style>
      <path d="m 40.53,37.55 c -1.71,0 -3.29,-0.3 -4.76,-0.9 -1.47,-0.6 -2.75,-1.46 -3.84,-2.56 -1.09,-1.1 -1.93,-2.39 -2.54,-3.86 -0.6,-1.47 -0.9,-3.07 -0.9,-4.81 0,-1.74 0.3,-3.34 0.9,-4.81 0.6,-1.47 1.45,-2.75 2.54,-3.84 1.09,-1.09 2.37,-1.93 3.84,-2.54 1.47,-0.6 3.06,-0.9 4.76,-0.9 1.7,0 3.33,0.3 4.79,0.9 1.46,0.6 2.73,1.45 3.81,2.54 1.09,1.09 1.93,2.37 2.54,3.84 0.6,1.47 0.9,3.07 0.9,4.81 0,1.74 -0.3,3.34 -0.9,4.81 -0.6,1.47 -1.45,2.76 -2.54,3.86 -1.09,1.1 -2.36,1.96 -3.81,2.56 -1.45,0.6 -3.05,0.9 -4.79,0.9 z m 0,-6.57 c 1.44,0 2.59,-0.52 3.46,-1.57 0.87,-1.04 1.3,-2.37 1.3,-3.99 0,-1.62 -0.43,-2.98 -1.3,-3.99 -0.87,-1.01 -2.02,-1.52 -3.46,-1.52 -1.44,0 -2.6,0.51 -3.46,1.52 -0.87,1.01 -1.3,2.34 -1.3,3.99 0,1.65 0.43,2.95 1.3,3.99 0.87,1.04 2.02,1.57 3.46,1.57 z" />
      <path d="M 4.14,36.84 C 1.86,36.84 0,34.99 0,32.7 V 4.14 C 0,1.86 1.85,0 4.14,0 h 0.05 c 2.28,0 4.14,1.85 4.14,4.14 v 1.89 c 0,2.04 0,4.05 0,6.03 0.91,-0.64 1.92,-1.14 3.05,-1.49 1.13,-0.35 2.35,-0.53 3.66,-0.53 1.92,0 3.61,0.44 5.07,1.31 1.46,0.88 2.61,2.13 3.43,3.76 0.82,1.63 1.23,3.59 1.23,5.88 V 32.7 c 0,2.28 -1.85,4.14 -4.14,4.14 h -0.05 c -2.28,0 -4.14,-1.85 -4.14,-4.14 V 22.1 c 0,-1.35 -0.31,-2.43 -0.93,-3.25 -0.62,-0.82 -1.61,-1.24 -2.95,-1.24 -1.34,0 -2.39,0.39 -3.13,1.16 -0.74,0.77 -1.11,1.88 -1.11,3.33 v 10.6 c 0,2.28 -1.85,4.14 -4.14,4.14 H 4.13 Z" />
      <path d="m 138.77,29.84 c -1.71,0 -3.29,-0.3 -4.76,-0.9 -1.47,-0.6 -2.75,-1.46 -3.84,-2.56 -1.09,-1.1 -1.93,-2.39 -2.54,-3.86 -0.6,-1.47 -0.9,-3.07 -0.9,-4.81 0,-1.74 0.3,-3.34 0.9,-4.81 0.6,-1.47 1.45,-2.75 2.54,-3.84 1.09,-1.09 2.37,-1.93 3.84,-2.54 1.47,-0.6 3.06,-0.9 4.76,-0.9 1.7,0 3.33,0.3 4.79,0.9 1.46,0.6 2.73,1.45 3.81,2.54 1.09,1.09 1.93,2.37 2.54,3.84 0.6,1.47 0.9,3.07 0.9,4.81 0,1.74 -0.3,3.34 -0.9,4.81 -0.6,1.47 -1.45,2.76 -2.54,3.86 -1.09,1.1 -2.36,1.96 -3.81,2.56 -1.45,0.6 -3.05,0.9 -4.79,0.9 z m 0,-6.57 c 1.44,0 2.59,-0.52 3.46,-1.57 0.87,-1.04 1.3,-2.37 1.3,-3.99 0,-1.62 -0.43,-2.98 -1.3,-3.99 -0.87,-1.01 -2.02,-1.52 -3.46,-1.52 -1.44,0 -2.59,0.51 -3.46,1.52 -0.87,1.01 -1.3,2.34 -1.3,3.99 0,1.65 0.43,2.95 1.3,3.99 0.87,1.04 2.02,1.57 3.46,1.57 z" />
      <path d="m 100.51,22.75 c -0.04,1.82 -0.59,3.29 -1.63,4.42 -1.04,1.13 -2.41,1.94 -4.12,2.43 -1.71,0.48 -3.61,0.66 -5.71,0.54 -1.18,-0.07 -2.38,-0.23 -3.58,-0.47 -1.62,-0.33 -2.76,-1.79 -2.72,-3.44 v 0 c 0.05,-1.91 1.88,-3.27 3.72,-2.76 h 0.04 c 1.22,0.34 2.33,0.56 3.32,0.64 0.99,0.08 1.8,0.02 2.42,-0.19 0.62,-0.21 0.94,-0.57 0.95,-1.1 0,-0.41 -0.24,-0.74 -0.75,-0.98 -0.51,-0.25 -1.17,-0.47 -1.98,-0.69 -0.82,-0.21 -1.68,-0.46 -2.6,-0.77 -0.91,-0.3 -1.77,-0.72 -2.58,-1.25 -0.81,-0.53 -1.46,-1.24 -1.95,-2.11 -0.49,-0.88 -0.72,-2 -0.69,-3.38 0.04,-1.64 0.55,-3.03 1.53,-4.16 0.98,-1.13 2.28,-1.98 3.9,-2.55 1.63,-0.56 3.44,-0.82 5.43,-0.77 1.26,0.03 2.53,0.19 3.81,0.49 1.28,0.3 2.25,1.52 2.22,2.88 v 0 c -0.05,2.07 -2.01,3.62 -4.01,3.1 -0.23,-0.06 -0.45,-0.11 -0.68,-0.16 -1.43,-0.3 -2.59,-0.37 -3.47,-0.19 -0.88,0.17 -1.33,0.56 -1.34,1.18 0,0.41 0.23,0.73 0.75,0.96 0.51,0.23 1.16,0.44 1.96,0.64 0.8,0.19 1.66,0.45 2.58,0.77 0.92,0.31 1.77,0.74 2.56,1.27 0.8,0.53 1.43,1.25 1.93,2.16 0.49,0.9 0.72,2.06 0.69,3.46 z" />
      <path d="m 123.28,24.19 c 0,0 0.03,0.04 0.05,0.06 1.8,2.22 0.21,5.54 -2.65,5.54 h -0.15 c -1.15,0 -2.24,-0.53 -2.95,-1.44 -0.75,-0.97 -1.51,-1.94 -2.27,-2.92 -1.12,-1.46 -2.26,-2.91 -3.38,-4.35 v 13.01 c 0,2.14 -1.74,3.88 -3.88,3.88 v 0 c -2.14,0 -3.88,-1.74 -3.88,-3.88 V 9.36 c 0,-2.14 1.74,-3.88 3.88,-3.88 v 0 c 2.14,0 3.88,1.74 3.88,3.88 v 6.23 c 1.15,-1.66 2.33,-3.34 3.52,-5.05 0.82,-1.18 1.63,-2.34 2.44,-3.5 0.69,-0.98 1.8,-1.56 3,-1.56 v 0 c 3.06,0 4.85,3.47 3.06,5.95 -0.14,0.2 -0.29,0.4 -0.43,0.6 -1.58,2.21 -3.15,4.4 -4.72,6.56 1.47,1.85 2.96,3.72 4.48,5.6 z" />
      <path d="m 173.63,19.06 c 0,0 0.03,-0.04 0.05,-0.06 1.8,-2.22 0.21,-5.54 -2.65,-5.54 h -0.15 c -1.15,0 -2.24,0.53 -2.95,1.44 -0.75,0.97 -1.51,1.94 -2.27,2.92 -1.12,1.46 -2.26,2.91 -3.38,4.35 V 9.16 c 0,-2.14 -1.74,-3.88 -3.88,-3.88 v 0 c -2.14,0 -3.88,1.74 -3.88,3.88 v 24.73 c 0,2.14 1.74,3.88 3.88,3.88 v 0 c 2.14,0 3.88,-1.74 3.88,-3.88 v -6.23 c 1.15,1.66 2.33,3.34 3.52,5.05 0.82,1.18 1.63,2.34 2.44,3.5 0.69,0.98 1.8,1.56 3,1.56 v 0 c 3.06,0 4.85,-3.47 3.06,-5.95 -0.14,-0.2 -0.29,-0.4 -0.43,-0.6 -1.58,-2.21 -3.15,-4.4 -4.72,-6.56 1.47,-1.85 2.96,-3.72 4.48,-5.6 z" />
      <path d="M 79.14,13.5 C 78.56,12.02 77.78,10.76 76.76,9.7 75.74,8.65 74.57,7.83 73.24,7.25 71.92,6.68 70.53,6.39 69.05,6.39 68,6.39 67,6.57 66.05,6.9 c -1.52,0.5 -2.66,1.53 -2.72,1.61 0,0 -0.35,-0.94 -0.97,-1.49 -0.65,-0.6 -1.51,-0.97 -2.45,-0.97 h -0.03 c -2,0 -3.62,1.62 -3.62,3.62 v 23.56 c 0,2.01 1.62,3.64 3.64,3.64 1.01,0 1.91,-0.41 2.57,-1.06 0.65,-0.66 1.06,-1.57 1.06,-2.57 v -4.75 c 0.74,0.63 1.58,1.1 2.5,1.44 0.93,0.34 1.91,0.5 2.98,0.5 1.5,0 2.9,-0.29 4.23,-0.88 1.32,-0.58 2.5,-1.41 3.52,-2.5 1.01,-1.07 1.8,-2.33 2.38,-3.81 C 79.72,21.78 80,20.15 80,18.39 80,16.63 79.72,14.96 79.14,13.5 Z m -7.67,8.97 c -0.84,1.01 -1.98,1.52 -3.42,1.52 -1.44,0 -2.55,-0.52 -3.4,-1.55 -0.85,-1.03 -1.27,-2.38 -1.27,-4.05 0,-1.67 0.42,-3.02 1.27,-4.04 0.85,-1.02 1.99,-1.53 3.4,-1.53 1.41,0 2.59,0.5 3.42,1.5 0.84,1 1.25,2.35 1.25,4.06 0,1.71 -0.41,3.07 -1.25,4.08 z" />
    </svg>
  `,
  styles: [`
    svg {
        width: 100%;
        height: 100%;
    }
  `]
})
export class LogoComponent {
    @Input() color: string = "white";
}
