<section class="card">
    <img alt="Náhled" src="/assets/img/preview.png">
    <h3>Rozvojová pomůcka</h3>
    <p>Logopedická rozvojová pomůcka je nástroj určený k podpoře rozvoje řečových a jazykových&hellip;</p>
    <div class="footer">
        <div class="chips">
            <span style="background: #f9986e;" title="Slovní zásoba">SZ</span>
            <span style="background: #f9986e;" title="Slovní zásoba">SZ</span>
        </div>
        <button class="add-to-cart">Koupit</button>
    </div>
</section>