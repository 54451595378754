import { Component } from '@angular/core';

@Component({
  selector: 'app-aid-card',
  templateUrl: './aid-card.component.html',
  styleUrls: ['./aid-card.component.scss']
})
export class AidCardComponent {

}
