<nav>
    <a routerLink="/" app-logo color="#86A483"></a>
    <div class="divider"></div>
    <div class="description">Rozvojové pomůcky <br>nejen pro komunikaci</div>
    <div class="search" *ngIf="search"><img src="/assets/img/icon/magnifying-glass.svg" alt=""><input type="search" placeholder="Co hledáte?"></div>
    <span class="spacer"></span>
    <ul class="entries">
        <li *ngFor="let e of entries"><a [routerLink]="e.path" routerLinkActive="active">{{e.text}}</a></li>
    </ul>
    <a routerLink="cart" class="cart" title="Košík"><img [src]="cart.isEmpty() ? '/assets/img/icon/cart-empty.svg' : '/assets/img/icon/cart-full.svg'" alt="Košík"></a>
</nav>