import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiConnectionService {

  constructor(private http: HttpClient) { }

  private api_url = "/api";

  public async getCategory() {
    return new Promise<any[]>((resolve, reject) => this.http.get<any[]>(this.api_url + "/category").subscribe(result => resolve(result)));
  }
}
