import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { PageComponent } from './page/page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './public/home/home.component';
import { LogoComponent } from './components/logo.component';
import { AidCategoriesComponent } from './components/aid-categories/aid-categories.component';
import { AidCardComponent } from './components/aid-card/aid-card.component';
import { NewestAidsComponent } from './components/newest-aids/newest-aids.component';

@NgModule({
  declarations: [
    PageComponent,
    NavbarComponent,
    HomeComponent,
    LogoComponent,
    AidCategoriesComponent,
    AidCardComponent,
    NewestAidsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [PageComponent]
})
export class AppModule { }
